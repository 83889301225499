import React, { Component } from "react"
import BackgroundImage from "gatsby-background-image"
import thumbnailStyles from "./thumbnail.module.scss"

class Thumbnail extends Component {
  getStyle = () => {
    if (this.props.currentIndex !== this.props.id) {
      return thumbnailStyles.overlay
    } else {
      return `${thumbnailStyles.overlay} ${thumbnailStyles.current}`
    }
  }

  render() {
    if (this.props.type === "image") {
      return (
        <button
          aria-label={`Zeige Bild ${this.props.id + 1}`}
          key={this.props.id}
          className={thumbnailStyles.container}
          onClick={this.props.handleCurrent.bind(this, this.props.id)}
        >
          <BackgroundImage
            fluid={this.props.fluid}
            className={thumbnailStyles.img}
          >
            <div className={this.getStyle()}></div>
          </BackgroundImage>
        </button>
      )
    } else if (this.props.type === "video") {
      return (
        <button
          aria-label="Zeige Video"
          key={this.props.id}
          className={thumbnailStyles.container}
          onClick={this.props.handleCurrent.bind(this, this.props.id)}
        >
          <video
            muted
            loop
            preload="metadata"
            playsInline
            className={thumbnailStyles.img}
            src={this.props.source}
          />
          <div className={this.getStyle()}>&#9654;</div>
        </button>
      )
    }
  }
}

export default Thumbnail
