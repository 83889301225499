import React, { Component } from "react"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
import Img from "gatsby-image"
import imageGalleryStyles from "./imageGallery.module.scss"
import Thumbnail from "./Thumbnail.js"

class ImageGallery extends Component {
  constructor(props) {
    super(props)

    // Filter function for this and following functions due to bug in contentful-source-gatsby plugin where it messes up the query and puts videos in images filed. Causes site to crash on second page call
    this.galleryItems = this.props.project.images
      .filter(image => image.file.contentType !== "video/mp4")
      .map(image => {
        return (
          <Img
            className={imageGalleryStyles.image}
            fluid={image.fluid}
            imgStyle={{ objectFit: "contain" }}
            key={image.file.fileName}
          />
        )
      })

    if (this.props.project.video !== null) {
      this.galleryItems.push(
        <video
          key={this.props.project.video.file.fileName}
          muted
          loop={true}
          autoPlay
          playsInline
          className={imageGalleryStyles.video}
          src={this.props.project.video.file.url}
        />
      )
    }

    this.thumbItems = this.props.project.images
      .filter(image => image.file.contentType !== "video/mp4")
      .map(image => {
        return image
      })
    if (this.props.project.video !== null) {
      this.thumbItems.push(this.props.project.video)
    }

    this.state = {
      galleryItems: this.galleryItems,
      thumbItems: this.thumbItems,
      currentIndex: 0,
    }
  }

  slideTo = id => this.setState({ currentIndex: id })

  onSlideChanged = e => this.setState({ currentIndex: e.item })

  slideNext = () => this.setState({ currentIndex: this.state.currentIndex + 1 })

  slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - 1 })

  render() {
    const { galleryItems, thumbItems, currentIndex } = this.state
    return (
      <>
        <div className={imageGalleryStyles.btnImageContainer}>
          <button
            aria-label="Vorheriges"
            onClick={() => this.slidePrev()}
            className={`${imageGalleryStyles.arrows} ${imageGalleryStyles.prev}`}
          ></button>
          <button
            aria-label="Nächstes"
            onClick={() => this.slideNext()}
            className={`${imageGalleryStyles.arrows} ${imageGalleryStyles.next}`}
          ></button>
          <div className={imageGalleryStyles.aliceCarousel}>
            <AliceCarousel
              autoHeight={true}
              dotsDisabled={true}
              buttonsDisabled={true}
              items={galleryItems}
              slideToIndex={currentIndex}
              onSlideChanged={this.onSlideChanged}
              preservePosition={true}
            />
          </div>
        </div>
        {/* iterating over galleryItems and not thumbItems because galleryItems contains 'type' to check for video or image */}
        <div className={imageGalleryStyles.thumbsContainer}>
          {galleryItems.map((image, i) => {
            if (galleryItems[i].type !== "video") {
              return (
                <Thumbnail
                  key={thumbItems[i].id}
                  fluid={thumbItems[i].fluid}
                  id={i}
                  currentIndex={currentIndex}
                  handleCurrent={this.slideTo}
                  type="image"
                />
              )
            } else if (galleryItems[i].type === "video") {
              return (
                <Thumbnail
                  key={thumbItems[i].id}
                  id={i}
                  source={`${thumbItems[i].file.url}#t=0.1`}
                  currentIndex={currentIndex}
                  handleCurrent={this.slideTo}
                  type="video"
                />
              )
            }
            return null
          })}
        </div>
      </>
    )
  }
}

export default ImageGallery
