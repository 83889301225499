import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import projektStyles from "../styles/projekt.module.scss"
import ReactMarkdown from "react-markdown"

import ImageGallery from "../components/image-gallery/ImageGallery"

function Projekt({ data: { contentfulProject } }) {
  let text
  if (contentfulProject.description !== null) {
    text = contentfulProject.description.description
  } else {
    text = ""
  }

  //Convert Markdown to plain text. 1st Replace all linebreaks (\n) with space. 2nd Remove markdown headings h1-3 (#, ##, ###) followed by a space. 3rd Slice to google 160 character recommendation
  const plainText = text
    .replace(/\s+/g, " ")
    .replace(/(#{1,3})\s/g, "")
    .slice(0, 160)

  return (
    <Layout>
      <SEO
        title={contentfulProject.name}
        description={plainText}
        image={contentfulProject.thumbnail.fixed.src}
      />
      <div className="container">
        <div className={projektStyles.titleContainer}>
          <h1>{contentfulProject.name}</h1>
          {/* <span className={projektStyles.category}>
            {contentfulProject.category.category}
          </span> */}
        </div>
        <div className={projektStyles.galleryTextContainer}>
          <div className={projektStyles.galleryContainer}>
            <ImageGallery
              project={contentfulProject}
              className={projektStyles.gallery}
            />
          </div>
          <div className={projektStyles.textContainer}>
            <ReactMarkdown source={text} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Projekt

export const query = graphql`
  query($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      name
      description {
        description
      }
      category {
        category
      }
      images {
        id
        file {
          fileName
          url
          contentType
        }
        fluid(maxWidth: 2600, quality: 70) {
          ...GatsbyContentfulFluid
          src
        }
      }
      thumbnail {
        fixed(width: 1200, quality: 70) {
          src
        }
      }
      video {
        id
        file {
          url
          fileName
          contentType
        }
      }
    }
  }
`
